import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Gallery from '@browniebroke/gatsby-image-gallery'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import GalleryPicker from "../components/GalleryPicker";

const GalleryPage = ({ data }) => {
  const images = data.images.edges.flatMap(edge => edge.node.photos)
  return (
    <Layout>
      <SEO title="Gallery"/>
      <Container>
        <PageTitle>Gallery</PageTitle>
          <GalleryPicker />
        <Gallery images={images}/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
    query ImagesForGallery {
        images: allContentfulGallery(sort: { fields: title }) {
            edges {
                node {
                    photos {
                        title,
                        description,
                        thumb: gatsbyImageData(
                            width: 270,
                            height: 270
                        ),
                        full: gatsbyImageData(layout: FULL_WIDTH) 
                    }
                }
            }
        }
    }
`

export default GalleryPage
